import React, {Component} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FlightSourceDestination from "./flightSourceDestination/flightSourceDestination";
import DelayedReason from "./delayReason/delayedReason"
import Details from "./itinerary/details";
import {FlightDetails} from "../model/flightDetails";

class SubmitFlow extends Component {
    state = {
        activeStep: 0,
        activeSubstep: 0,
        stepsSubstepsCount: [4, 1, 1, 1],
        completed: [],
        skipped: [],
        stepsDisabled: [[true, true, true, true], [true], [true], [true]],
        stepsLabels: [' Eligibility Check', 'Additional Information', 'Documents', 'Finish'],
        flightDetails: new FlightDetails(),
    };
    style = {
        root: {
            width: '100%',
        },
        button: {
            marginRight: "theme.spacing(1)",
        },
        backButton: {
            marginRight: "theme.spacing(1)",
        },
        completed: {
            display: 'inline-block',
        },
        instructions: {
            marginTop: "theme.spacing(1)",
            marginBottom: "theme.spacing(1)",
        },
    }

    handleDisableChange = (disabled) => {
        if (this.state.stepsDisabled[this.state.activeStep][this.state.activeSubstep] !== disabled) {
            const copyStepsDisabled = this.state.stepsDisabled;
            copyStepsDisabled[this.state.activeStep][this.state.activeSubstep] = disabled;
            this.setState({stepsDisabled: copyStepsDisabled});
        }
    };
    handleEnable = () => {
        this.handleDisableChange(false);
    };
    handleDisable = () => {
        this.handleDisableChange(true);
    };

    handleFlightSourceDestinationChange = (flightSourceDestination) => {
        this.setState({flightDetails: flightSourceDestination});
    };

    getStepContent = () => {
        switch (this.state.activeStep) {
            case 0:
                switch (this.state.activeSubstep) {
                    case 0:
                        return <FlightSourceDestination
                            handleFlightSourceDestinationChange={this.handleFlightSourceDestinationChange}
                                handleEnable={this.handleEnable}
                            handleDisable={this.handleDisable}/>;
                    case 1:
                        return <DelayedReason destinationAirport={this.state.flightDetails.flightDestination}
                                              handleEnable={this.handleEnable}
                                              handleDisable={this.handleDisable}/>;
                    case 2:
                        return <Details destinationAirport={this.state.flightDetails.flightDestination}
                                        sourceAirport={this.state.flightDetails.flightSource}/>
                    default:
                        return 'Unknown step';
                }
            case 1:
                return 'Step 2: What is an ad group anyways?';
            case 2:
                return 'Step 3: This is the bit I really care about!';
            default:
                return 'Unknown step';
        }
    };

    totalSteps = () => {
        return this.state.stepsLabels.length;
    };

    isStepOptional = (step) => {
        return false;
    };

    handleSkip = () => {
        if (!this.isStepOptional(this.state.activeStep)) {
            // You probably want to guard against something like this
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        this.setState({activeStep: this.state.activeStep});
        let prevSkipped = this.state.skipped;
        prevSkipped[this.state.skipped.length] = this.state.activeStep;
        this.setState({skipped: prevSkipped});
    };

    skippedSteps = () => {
        return this.state.skipped.length;
    };

    completedSteps = () => {
        return this.state.completed.length;
    };

    allStepsCompleted = () => {
        return this.completedSteps() === this.totalSteps() - this.skippedSteps();
    };

    isLastStep = () => {
        return this.state.activeStep === this.totalSteps() - 1;
    };

    handleNext = () => {
        if (this.state.activeSubstep === this.state.stepsSubstepsCount[this.state.activeStep]) {
            const newActiveStep =
                this.isLastStep() && !this.allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed
                      // find the first step that has been completed
                    this.getSteps().findIndex((step, i) => !this.state.completed[i])
                    : this.state.activeStep + 1;
            this.setState({activeStep: newActiveStep});
        } else {
            this.setState({activeSubstep: this.state.activeSubstep + 1});
        }
    }
    ;

    handleBack = () => {
        this.setState({activeStep: this.state.activeStep - 1});
    };

    handleComplete = () => {
        if (this.state.activeSubstep === this.state.stepsSubstepsCount[this.state.activeStep]) {
            const newCompleted = this.state.completed;
            newCompleted[newCompleted.length] = this.state.activeStep;
            this.setState({completed: newCompleted});

            /**
             * Sigh... it would be much nicer to replace the following if conditional with
             * `if (!this.allStepsComplete())` however state is not set when we do this,
             * thus we have to resort to not being very DRY.
             */
        }
        if (this.state.completed.length !== this.totalSteps() - this.skippedSteps()) {
            this.handleNext();
        }
    };

    handleReset = () => {
        this.setState({activeStep: 0});
        this.setState({completed: []});
        this.setState({skipped: []});
    };

    isStepSkipped = (step) => {
        return this.state.skipped.filter(skippedStep => skippedStep === step).length === 1;
    };

    isStepComplete(step) {
        return this.state.completed.filter(completedStep => completedStep === step).length === 1;
    }

    render() {
        return (
            <div key={this.state.activeStep} style={this.style.root}>
                <Stepper key={this.state.activeStep} alternativeLabel nonLinear activeStep={this.state.activeStep}>
                    <Step key="1">
                        <StepButton
                            completed={this.isStepComplete(0)}>
                            {this.state.stepsLabels[0]}
                        </StepButton>
                    </Step>
                    <Step key="2">
                        <StepButton
                            completed={this.isStepComplete(1)}>
                            {this.state.stepsLabels[1]}
                        </StepButton>
                    </Step>
                    <Step key="3">
                        <StepButton
                            completed={this.isStepComplete(2)}>
                            {this.state.stepsLabels[2]}
                        </StepButton>
                    </Step>
                    <Step key="4">
                        <StepButton
                            completed={this.isStepComplete(3)}>
                            {this.state.stepsLabels[3]}
                        </StepButton>
                    </Step>
                </Stepper>
                <div>
                    {this.allStepsCompleted() ? (
                        <div>
                            <Typography style={this.style.instructions}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={this.handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <div>
                            <div style={this.style.instructions}>{this.getStepContent()}</div>
                            <div>
                                <Button disabled={this.state.activeStep === 0} onClick={this.handleBack}
                                        style={this.style.button}>
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleComplete}
                                    style={this.style.button}
                                    disabled={this.state.stepsDisabled[this.state.activeStep][this.state.activeSubstep]}
                                >
                                    {this.state.activeStep === this.totalSteps() - 1 ? 'Finish' : 'Continue'}
                                </Button>
                                {this.isStepOptional(this.state.activeStep) && !this.isStepComplete(this.state.activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleSkip}
                                        style={this.style.button}
                                    >
                                        Skip
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };
}

export default SubmitFlow;
