import React, {Component} from "react";
import '../../styles/radioBoxStyle.css'
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";

const GreenRadio = withStyles({
    root: {
        color: "#9bc645",
        '&$checked': {
            color: "#9bc645",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

class TotalDelay extends Component {

    state = {
        selection: "",
    };

    handleChange = (event) => {
        this.setState({selection: event.target.value});
        this.props.handleTotalDelay(event.target.value);
    };

    render() {
        return (
            <div><p className="subSection">What was the total delay once you arrived at {this.props.destinationAirport.city} + ({this.props.destinationAirport.IATA})</p>
                <div>
                    <div className="radioLeftDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "0 - 1 hours"}
                            onChange={this.handleChange}
                            value="0 - 1 hours"
                            name="radio-button-demo"
                            inputProps={{'aria-label': '0 - 1 hours'}}
                        />
                        <span>0 - 1 hours</span>
                    </div>
                    <div className="radioCenterDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "1 - 2 hours"}
                            onChange={this.handleChange}
                            value="1 - 2 hours"
                            name="radio-button-demo"
                            inputProps={{'aria-label': '1 - 2 hours'}}
                        />
                        <span>1 - 2 hours</span>
                    </div>
                    <div className="radioCenterDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "2 - 3 hours"}
                            onChange={this.handleChange}
                            value="2 - 3 hours"
                            name="radio-button-demo"
                            inputProps={{'aria-label': '2 - 3 hours'}}
                        />
                        <span>2 - 3 hours</span>
                    </div>
                    <div className="radioCenterDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "3 - 4 hours"}
                            onChange={this.handleChange}
                            value="3 - 4 hours"
                            name="radio-button-demo"
                            inputProps={{'aria-label': '3 - 4 hours'}}
                        />
                        <span>3 - 4 hours</span>
                    </div>
                    <div className="radioCenterDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "4+ hours"}
                            onChange={this.handleChange}
                            value="4+ hours"
                            name="radio-button-demo"
                            inputProps={{'aria-label': '4+ hours'}}
                        />
                        <span>4+ hours</span>
                    </div>
                    <div className="radioRightDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Never arrived"}
                            onChange={this.handleChange}
                            value="Never arrived"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Never arrived'}}
                        /><span>Never arrived</span>
                    </div>
                </div>
            </div>);
    }
}

export default TotalDelay;
