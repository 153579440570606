import React, {Component} from 'react';
import '../../styles/style.css'
import FlightLandIcon from '@material-ui/icons/FlightLand';
import AirlineField from "./airline";
import FlightNo from "./flightNo";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

class Details extends Component {

    state = {
        airline: "",
        flightNo: "",
        departureDate: new Date(),
    };

    handleAirlineChanged = (airline) => {
        airline ? console.log(airline.toString()) : console.log("");
        this.setState({airline: airline});
    };

    flightNoChanged = (value) => {
        this.setState({flightNo: value});
    };

    handleDateChange = (newDate) => {
        this.setState({departureDate: newDate});
    };

    render() {
        return (<form className="form" noValidate autoComplete="off">
            <div className="formDiv">
                <div><p className="subSection">Itinerary details for your disrupted flight</p>
                    <p>Please give us the full itinerary so we can make sure we claim for the full amount.</p>
                    <p>{this.props.sourceAirport ? this.props.sourceAirport.city + "(" + this.props.sourceAirport.IATA + ")" : ""}
                        <FlightLandIcon/>
                        {this.props.destinationAirport ? this.props.destinationAirport.city + "(" + this.props.destinationAirport.IATA + ")" : ""}
                    </p>
                    <AirlineField handleChange={this.handleAirlineChanged}
                                  key="airlineField"
                                  header="AIRLINE"
                                  placeholder="e.g. British Airways "
                                  required={true}/>
                    <FlightNo handleChange={this.handleAirlineChanged}
                              key="flightNo"
                              header="FLIGHT NO."
                              airline={this.state.airline}
                              flightNoChanged={this.flightNoChanged}
                              required={true}/>
                    <div style={{width:"250px",display:"inline-block", marginBottom:"20px",}}>
                        <p style={{margin:"0px"}}>DEPARTURE DATE</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={this.state.departureDate}
                                    onChange={this.handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
            </div>
        </form>);
    }
}

export default Details;
