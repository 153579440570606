import React from 'react';
import './App.css';
import SubmitFlow from "./components/submitFlow";

function App() {
    return (
        <div className="App">
            <div
                style={{
                    padding: "4px",
                }}>
            <SubmitFlow/>
            </div>
        </div>
    );
}

export default App;
