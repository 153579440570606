import React, {Component} from 'react';
import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import AirportField from "./airportField";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import ExtraConnection from "./extraConnection";
import '../../styles/radioBoxStyle.css'
import './../../styles/style.css'

const GreenRadio = withStyles({
    root: {
        color: "#9bc645",
        '&$checked': {
            color: "#9bc645",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

class ConnectedFlights extends Component {
    state = {
        selection: "No",
        extraConnections: [],
        defaultConnection: null,
        extraConnectionId: 1,
        hasExtraConnection: false,
        isValid: true,
    };

    constructor(props) {
        super(props);
        if (this.props.value) {
            this.setState({selection: this.props.value});
        }
    }


    handleChange = (event) => {
        this.setState({selection: event.target.value});
        if (event.target.value === "Yes" && !this.state.hasExtraConnection) {
            this.setState({hasExtraConnection: true});
        } else if (event.target.value === "No" && this.state.hasExtraConnection){
            this.setState({hasExtraConnection: false});
        }
    };
    allConnectedFlightsValid = () => {
        return this.state.defaultConnection !== null && this.state.extraConnections.filter(flight => flight.textValue === "").length === 0;
    };

    allConnectedFlights = () => {
        let allConnections = [];
        allConnections.push(this.state.defaultConnection)
        this.state.extraConnections.forEach(extraConnection => allConnections.push(extraConnection.airport));
        return allConnections;
    }
    handleAddExtraConnection = () => {
        this.setState({extraConnections: this.state.extraConnections.concat([{id: this.state.extraConnectionId, textValue: ""}])});
        this.setState({extraConnectionId: this.state.extraConnectionId + 1});
    };

    handleRemoveExtraConnection = (removedId) => {
        this.setState({extraConnections: this.state.extraConnections.filter(c => c.id !== removedId)});
    };

    componentDidUpdate = () => {
        let currentConnectedFlightsStatus = this.allConnectedFlightsValid();
        if (this.state.hasExtraConnection && currentConnectedFlightsStatus !== this.state.isValid) {
            this.setState({isValid: currentConnectedFlightsStatus});
            this.props.isValid(currentConnectedFlightsStatus)
            this.props.handleConnectedFlightsChange(this.allConnectedFlights());
        }
        if (!this.state.isValid && !this.state.hasExtraConnection) {
            this.setState({isValid: true});
            console.log("is valid = " + true);
            console.log("hasExtraConnection" +  this.state.hasExtraConnection);
            this.props.isValid(true);
            this.props.handleConnectedFlightsChange(this.allConnectedFlights());
        }
    };

    handleDefaultConnectionChange = (value) => {
        this.setState({defaultConnection: value});
    };
        handleExtraConnectionChange = (value, id) => {
        console.log("extra connection " + id + " " + value);
        let copyExtraConnections = this.state.extraConnections;
        copyExtraConnections[id-1].textValue = value ? value.toString() : "";
        copyExtraConnections[id-1].airport = value;
        this.setState({extraConnections: copyExtraConnections});
    };

    render() {
        return (
            <div>
                <div>
                    <div className="radioLeftDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Yes"}
                            onChange={this.handleChange}
                            value="Yes"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Yes'}}
                        />
                        <span>Yes</span>
                    </div>
                    <div className="radioRightDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "No"}
                            onChange={this.handleChange}
                            value="No"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'No'}}
                        /><span>No</span>
                    </div>
                </div>
                {this.state.hasExtraConnection ?
                    <div className="formDiv">
                        <p className="subSection">Where did you have connections?</p>
                        <div>

                            <AirportField handleChange={this.handleDefaultConnectionChange} key="connected"
                                          placeholder="e.g. London or LHR"
                                          required={true}/>
                        </div>
                        {this.state.extraConnections.map(c =>
                            <ExtraConnection key={c.id} handleChange={this.handleExtraConnectionChange} id={c.id}
                                             onRemove={this.handleRemoveExtraConnection.bind(this, c.id)}>{c.id}</ExtraConnection>
                        )}
                        <div
                            style={{float: "left", width: "100%"}}>
                            <Fab size="medium" aria-label="add"
                                 style={{marginTop: "10px", backgroundColor: "#7fac26"}}>
                                <AddIcon onClick={this.handleAddExtraConnection}/>
                            </Fab>
                        </div>
                    </div> : ""}
            </div>
        );
    }
}

export default ConnectedFlights;

