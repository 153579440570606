import React, {Component} from "react";
import '../../styles/radioBoxStyle.css'
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";

const GreenRadio = withStyles({
    root: {
        color: "#9bc645",
        '&$checked': {
            color: "#9bc645",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

class WhatHappenedToTheFlight extends Component {

    state = {
        selection: "",
    };

    handleChange = (event) => {
        this.setState({selection: event.target.value})
        this.props.handleChange(event.target.value);

    };

    render() {
        return (
            <div><p className="subSection">What happened to the flight?</p>
                <p>Select below what happened to your flight to get the compensation.</p>
                <div>
                    <div className="radioLeftDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Delayed flight"}
                            onChange={this.handleChange}
                            value="Delayed flight"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Delayed flight'}}
                        />
                        <span>Delayed flight</span>
                    </div>
                    <div className="radioCenterDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Canceled flight"}
                            onChange={this.handleChange}
                            value="Canceled flight"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Canceled flight'}}
                        />
                        <span>Canceled flight</span>
                    </div>
                    <div className="radioRightDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Denied boarding"}
                            onChange={this.handleChange}
                            value="Denied boarding"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Denied boarding'}}
                        /><span>Denied boarding</span>
                    </div>
                </div>
            </div>);
    }
}

export default WhatHappenedToTheFlight;
