import React, {Component} from 'react';
import '../../styles/style.css'
import WhatHappenedToTheFlight from './whatHappenedToTheFlight'
import TotalDelay from "./totalDelay";
import DidYouVolunteer from './didYouVolunteer'
import DelayReason from "./delayReason";
import CancellationNotification from "./cancellationReason";

class DelayedReason extends Component {

    state = {
        whatHappenedToTheFlight: "",
        isTotalDelayVisible: false,
        isDidYouVolunteerVisible: false,
        totalDelay: "",
        didYouVolunteer: null,
        cancelReason: null,
        delayReason: null,
        isDelayReasonVisible: false,
        isCancelReasonVisible: false,
        isValid: false,
        lastSection: null,
        delayReasonValid: false,
    };

    totalDelay = (value) => {
        this.setState({totalDelay: value});
        if(this.state.whatHappenedToTheFlight === "Denied boarding"){
            this.setState({didYouVolunteer: false});
        }
    };
    whatHappenedToTheFlight = (value) => {
        this.setState({whatHappenedToTheFlight: value});
        this.setState({isTotalDelayVisible: true});

    };
    didYouVolunteer = (value) => {
        this.setState({didYouVolunteer: value});
    };
    delayReason = (value) => {
        this.setState({delayReason: value});
    };
    cancelReason = (value) => {
        this.setState({cancelReason: value});
    };
    handleCancellationReasonValid = (value) => {
        this.setState({cancellationReason: value});
    };
    handleDelayReasonValid = (value) => {
        this.setState({delayReasonValid: value});
    };

    componentDidUpdate = () => {
        let computedValidity;
        let lastSection;
        if(this.state.whatHappenedToTheFlight !== this.state.lastSection && this.state.totalDelay) {
            this.setState({lastSection: this.state.whatHappenedToTheFlight});
            lastSection = true;
            if (this.state.whatHappenedToTheFlight === 'Delayed flight') {
                this.setState({isDelayReasonVisible: true});
                this.setState({
                    isCancelReasonVisible: null,
                    isDidYouVolunteerVisible: null,
                    cancelReason: null,
                    didYouVolunteer: null,
                });

            } else if (this.state.whatHappenedToTheFlight === 'Canceled flight') {
                this.setState({isCancelReasonVisible: true});
                this.setState({
                    isDelayReasonVisible: null,
                    isDidYouVolunteerVisible: null,
                    delayReasonValid: null,
                    didYouVolunteer: null,
                });

            } else {
                this.setState({isDidYouVolunteerVisible: true});
                this.setState({
                    isDelayReasonVisible: null,
                    isCancelReasonVisible: null,
                    didYouVolunteer: false,
                    cancelReason: null,
                    delayReasonValid: null,
                });
            }
        }
        computedValidity = this.state.whatHappenedToTheFlight && this.state.totalDelay && (lastSection || this.state.cancelReason || this.state.delayReasonValid || this.state.didYouVolunteer !== null);
        if (this.state.isValid !== computedValidity) {
            this.setState({isValid: computedValidity});
            if (computedValidity) this.props.handleEnable();
            else this.props.handleDisable();
        }
    };

    render() {
        return (<form className="form" noValidate autoComplete="off">
            <div className="formDiv">
                <WhatHappenedToTheFlight handleChange={this.whatHappenedToTheFlight}/>
                {this.state.isTotalDelayVisible ?
                    <TotalDelay destinationAirport={this.props.destinationAirport}
                                handleTotalDelay={this.totalDelay}/> : ""}
                {this.state.isDidYouVolunteerVisible ?
                    <DidYouVolunteer handleDidYouVolunteer={this.didYouVolunteer}/> : ""}
                {this.state.isDelayReasonVisible ?
                    <DelayReason handleDelayReasonChange={this.delayReason}
                                 isValid={this.handleDelayReasonValid}/> : ""}
                {this.state.isCancelReasonVisible ?
                    <CancellationNotification handleCancelReasonChange={this.cancelReason}
                                              isValid={this.handleCancellationReasonValid}/> : ""}
            </div>
        </form>);
    }
}

export default DelayedReason;
