import React, {Component} from 'react';
import AirportField from "./airportField";
import ClearSharpIcon from '@material-ui/icons/ClearSharp';

class ExtraConnection extends Component {
    handleChange = (value) => {
        this.props.handleChange(value, this.props.id);
    };

    render() {
        return (
            <div style={{width: "100%", float: "left"}}>
                <AirportField  handleChange={this.handleChange} key={this.props.id} placeholder="e.g. London or LHR" required={true}/>
                <div style={{margin: "20px"}}>
                    <ClearSharpIcon fontSize="large" onClick={this.props.onRemove}>{this.props.id}</ClearSharpIcon>
                </div>
            </div>);
    }
}

export default ExtraConnection;
