import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyDGD0cim6KWMVWlECd9OZF3Y3-pBmFHU8U",
    authDomain: "myclaim-5b52e.firebaseapp.com",
    databaseURL: "https://myclaim-5b52e.firebaseio.com",
    projectId: "myclaim-5b52e",
    storageBucket: "myclaim-5b52e.appspot.com",
    messagingSenderId: "121868786599",
    appId: "1:121868786599:web:b887792aff98e9b5ba3482"
}

var app = firebase.initializeApp(config);
var db = firebase.firestore(app);

export default db;
