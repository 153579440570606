export class Airport {
    constructor(city, IATA, name) {
        this.city = city;
        this.IATA = IATA;
        this.name = name;
    }

    toString = () =>
    {
        return this.name ? this.name + " Airport, " + this.city + "(" + this.IATA + ")" : "";
    }
}
