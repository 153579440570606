import React, {Component} from 'react';
import db from '../../firebase'
import TextField from '@material-ui/core/TextField';
import {Airport} from "../../model/airport";
import './../../styles/airportField.css'

class AirportField extends Component {
    state = {
        invalid: false,
        error: "",
        typing: false,
        airport: null,
        textValue: "",
        selected: false,
    };

    style = {
        marginLeft: 0,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        width: "100%",
        float: "left",
        maxWidth: "300px"
    };

    constructor(props) {
        super(props);
        if (this.props.value) {
            this.setState({airport: this.props.value});
            this.setState({textValue: this.props.value.toString()})
        }

    }

    handleBlur = () => {
        if (this.state.selected === false) {
            this.setState({textValue: ""});
            this.setState({airport: null});
        }
        this.props.handleChange(this.state.airport);
        this.setState({typing: false});
        this.setState({airports: []})
    };

    handleSelection = event => {
        this.state.airports
            .filter(airport => airport.id === event.target.children[0].textContent)
            .forEach(airport => {
                    let newAirport = new Airport(airport.data().city, airport.data().IATA, airport.data().airportName);
                    this.setState({selected: true});
                    this.setState({textValue: newAirport.toString()});
                    this.setState({airport: newAirport})
                }
            );
    };

    handleChange = event => {
        this.setState({textValue: event.target.value});
        if (this.props.required && event.target.value.length === 0) {
            this.setState({
                invalid: true,
                error: "Field cannot be empty",
            })
        } else {
            this.setState({
                invalid: false,
                error: "",
            })
        }
        if (this.props.required && event.target.value.length >= 2) {
            this.getAirports(event);
        }
    };

    getAirports(event) {
        let filteredAirports = [];
        let filters = ["IATA", "airportName", "city", "country", "ICAO", "FAA"];
        let airportsRef = db.collection('airport');

        this.getAirportsByFilterField(airportsRef, filters, 0, event.target.value, filteredAirports);
    }

    getAirportsByFilterField(airportsRef, filters, position, value, filteredAirports) {
        if (position === filters.length) {
            this.setState({airports: filteredAirports});
            this.setState({typing: true});
            return;
        }
        if (value === null) {
            this.getAirportsByFilterField(airportsRef, filters, position + 1, value, filteredAirports)
        } else {
            airportsRef.orderBy(filters[position]).startAt(value).endAt(value + "\uf8ff").get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        filteredAirports.push(doc);
                    });
                    if (filteredAirports.length < 10 && position < filters.length) {
                        this.getAirportsByFilterField(airportsRef, filters, position + 1, value, filteredAirports)
                    } else {
                        this.setState({airports: filteredAirports});
                        this.setState({typing: true});
                    }
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    }

    getKey = airport => {
        return airport.id + "" + this.props.childrenKey;
    }

    render() {
        return (
            <div style={this.style}>
                <p style={{margin: "0", textAlign: "left", maxWidth: "300px"}}>{this.props.header}</p>
                <TextField
                    style={{margin: "0", width: "100%", maxWidth: "300px"}}
                    id="standard-select-currency"
                    placeholder={this.props.placeholder}
                    value={this.state.textValue}
                    helperText={this.state.invalid && this.state.error}
                    error={this.state.invalid}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    margin="normal"
                    variant="outlined"
                />
                {this.state.typing &&
                <ul className="list">
                    {
                        this.state.airports.map(airport =>
                            <li
                                style={{
                                    lineHeight: "normal",
                                    color: "#536273",
                                    cursor: "pointer",
                                    fontWeight: 300,
                                    listStyle: "none",
                                    paddingLeft: "1",
                                    textAlign: "left",
                                }}
                                key={this.getKey(airport)}
                                onMouseDown={this.handleSelection}
                            >
                                <div value={airport.id}
                                     style={{
                                         position: "relative",
                                     }}>
                                    <span style={{
                                        marginRight: "5px",
                                        minWidth: "40px",
                                        display: "inline-block",
                                        fontWeight: 400,
                                        width: "40px"
                                    }}>{airport.data().IATA}</span>
                                    {airport.data().airportName} Airport
                                </div>
                            </li>
                        )
                    }
                </ul>}
            </div>
        );
    }
}

export default AirportField;
