import React, {Component} from 'react';
import '../../styles/style.css'
import TextField from "@material-ui/core/TextField";
import {Airline} from "../../model/airline";
import db from "../../firebase";

class AirlineField extends Component {
    state = {
        invalid: false,
        error: "",
        typing: false,
        airline: null,
        textValue: "",
        selected: false,
    };

    style = {
        marginLeft: 0,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        width: "100%",
        float: "left",
        maxWidth: "300px"
    };

    constructor(props) {
        super(props);
        if (this.props.value) {
            this.setState({airline: this.props.value});
            this.setState({textValue: this.props.value.toString()})
        }

    }

    handleBlur = () => {
        if (this.state.selected === false) {
            this.setState({textValue: ""});
            this.setState({airline: null});
        }
        this.props.handleChange(this.state.airline);
        this.setState({typing: false});
        this.setState({airlines: []})
    };

    handleSelection = event => {
        console.log(event.target.childNodes[0].nodeValue);
        this.state.airlines
            .filter(airline => airline.data().name === event.target.childNodes[0].nodeValue)
            .forEach(airline => {
                    let newAirline = new Airline(airline.data().name, airline.data().code);
                    this.setState({selected: true});
                    this.setState({textValue: newAirline.name});
                    this.setState({airline: newAirline})
                }
            );
    };

    handleChange = event => {
        this.setState({textValue: event.target.value});
        if (this.props.required && event.target.value.length === 0) {
            this.setState({
                invalid: true,
                error: "Field cannot be empty",
            })
        } else {
            this.setState({
                invalid: false,
                error: "",
            })
        }
        if (this.props.required && event.target.value.length >= 2) {
            this.getAirlines(event);
        }
    };

    getAirlines(event) {
        let filteredAirlines = [];
        let filters = ["searchCode", "searchName"];
        let airlinesRef = db.collection('airline');

        this.getAirlinesByFilterField(airlinesRef, filters, 0, event.target.value.toLowerCase(), filteredAirlines);
    }

    getAirlinesByFilterField(airlinesRef, filters, position, value, filteredAirlines) {
        if (position === filters.length) {
            this.setState({airlines: filteredAirlines});
            this.setState({typing: true});
            return;
        }
        if (value === null) {
            this.getAirlinesByFilterField(airlinesRef, filters, position + 1, value, filteredAirlines)
        } else {
            airlinesRef.orderBy(filters[position]).startAt(value).endAt(value + "\uf8ff").get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        filteredAirlines.push(doc);
                    });
                    if (filteredAirlines.length < 5 && position < filters.length) {
                        this.getAirlinesByFilterField(airlinesRef, filters, position + 1, value, filteredAirlines)
                    } else {
                        this.setState({airlines: filteredAirlines});
                        this.setState({typing: true});
                    }
                })
                .catch(function (error) {
                    console.log("Error getting documents: ", error);
                });
        }
    }

    getKey = airline => {
        return airline.id + "" + this.props.childrenKey;
    }
    render() {
        return (<div style={{display:"inline-block", marginRight:"20px", marginBottom:"20px",}}>
                <p style={{margin: "0", textAlign: "left", maxWidth: "300px"}}>{this.props.header}</p>
                <TextField
                    style={{margin: "0", width: "100%", maxWidth: "300px"}}
                    id="standard-select-currency"
                    placeholder={this.props.placeholder}
                    value={this.state.textValue}
                    helperText={this.state.invalid && this.state.error}
                    error={this.state.invalid}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    margin="normal"
                    variant="outlined"
                />
                {this.state.typing &&
                <ul className="list">
                    {
                        this.state.airlines.map(airline =>
                            <li
                                style={{
                                    lineHeight: "normal",
                                    color: "#536273",
                                    cursor: "pointer",
                                    fontWeight: 300,
                                    listStyle: "none",
                                    paddingLeft: "1",
                                    textAlign: "left",
                                }}
                                key={this.getKey(airline)}
                                onMouseDown={this.handleSelection}
                            >
                                <div value={airline.id}
                                     style={{
                                         position: "relative",
                                     }}>
                                    {airline.data().name} 
                                </div>
                            </li>
                        )
                    }
                </ul>
                }
            </div>
        );
    }
}
export default AirlineField;
