import React, {Component} from "react";
import '../../styles/radioBoxStyle.css'
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";

const GreenRadio = withStyles({
    root: {
        color: "#9bc645",
        '&$checked': {
            color: "#9bc645",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

class DidYouVolunteer extends Component {

    state = {
        selection: "No",
    };

    handleChange = (event) => {
        this.setState({selection: event.target.value})
        this.props.handleDidYouVolunteer(event.target.value);
    };

    render() {
        return (
            <div><p className="subSection">Did you volunteer to get bumped?{this.props.arrivingAirport}</p>
                <div>
                    <div className="radioLeftDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Yes"}
                            onChange={this.handleChange}
                            value="Yes"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Yes'}}
                        />
                        <span>Yes</span>
                    </div>
                    <div className="radioRightDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "No"}
                            onChange={this.handleChange}
                            value="No"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'No'}}
                        /><span>No</span>
                    </div>
                </div>
            </div>);
    }
}

export default DidYouVolunteer;
