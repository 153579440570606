import React, {Component} from 'react';
import '../../styles/style.css'
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";

const CustomTextFieldLeft = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `4px 0 0 4px`,
                borderWidth: `1.4px thin 1.4px 1.4px`
            },
        },
    },
})(TextField);
const CustomTextFieldRight = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0 4px 4px 0`,
                borderWidth: `1.4px 1.4px 1.4px thin`
            },
        },
    },
})(TextField);
class FlightNo extends Component {
    state = {
        invalid: false,
        error: "",
        typing: false,
        airline: null,
        textValue: "",
        selected: false,
        flightNo: "",
    };

    style = {
        marginLeft: 0,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        width: "100%",
        float: "left",
        maxWidth: "300px"
    };

    handleChange = (event) => {
        let value = event.target.value;
        this.setState({flightNo: value});
        this.props.flightNoChanged(value);
    }
    render() {
        return (<div style={{display:"inline-block", marginRight:"20px",  marginBottom:"20px",}}>
            <p style={{margin: "0", textAlign: "left", maxWidth: "300px"}}>{this.props.header}</p>
            <CustomTextFieldLeft
                style={{margin: "0", width: "100%", maxWidth: "65px"}}
                id="standard-select-currency"
                value={this.props.airline?this.props.airline.code:""}
                helperText={this.state.invalid && this.state.error}
                error={this.state.invalid}
                margin="normal"
                variant="outlined"
                disabled={true}
            />
            <CustomTextFieldRight
                style={{margin: "0", width: "100%", maxWidth: "100px"}}
                id="standard-select-currency"
                value={this.state.flightNo}
                placeholder="1234"
                helperText={this.state.invalid && this.state.error}
                error={this.state.invalid}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
            />
        </div>)};
}
export default FlightNo;
