import React, {Component} from 'react';
import AirportField from './airportField'
import ConnectedFlights from "./connectedFlights";
import InfoIcon from '@material-ui/icons/Info';
import '../../styles/style.css'
import {FlightDetails} from '../../model/flightDetails'

class FlightSourceDestination extends Component {
    state = {
        sourceAirport: "",
        destinationAirport: "",
        connectedFlights: [],
        isConnectedFlightsValid: true,
        flightDetails: new FlightDetails("","",[]),
    };

    isValid = () => {
        return !!(this.state.sourceAirport !== "" && this.state.destinationAirport !== "" &&
            (this.state.isConnectedFlightsValid === true));

    };
    handleSourceChange = (msg) => {
        this.setState({sourceAirport: msg});
        this.props.handleFlightSourceDestinationChange(new FlightDetails(msg, this.state.destinationAirport, this.state.connectedFlights));

    };
    handleDestinationChange = (msg) => {
        this.setState({destinationAirport: msg});
        this.props.handleFlightSourceDestinationChange(new FlightDetails(this.state.sourceAirport, msg, this.state.connectedFlights));

    };
    handleConnectedFlightsChange = (newConnectedFlights) => {
        this.setState({connectedFlights: newConnectedFlights});
        this.props.handleFlightSourceDestinationChange(new FlightDetails(this.state.sourceAirport, this.state.destinationAirport, newConnectedFlights));

    };

    componentDidUpdate() {
        if (this.isValid()) {
            this.props.handleEnable(true);
        } else {
            this.props.handleDisable(false);
        }
    };

    isConnectedFlightsValidityChanged = (valid) => {
        console.log("is connec valid " + valid);
        this.setState({isConnectedFlightsValid: valid});
    };

    render() {
        return (
            <form className="form" noValidate autoComplete="off">
                <div className="formDiv">
                    <p className="subSection">Where did you fly?</p>
                    <div>
                        <AirportField handleChange={this.handleSourceChange} key="sourceAirport"
                                      childrenKey="sourceAirport" header="DEPARTED FROM"
                                      placeholder="e.g. New York or JFK"
                                      required={true}/>
                        <AirportField handleChange={this.handleDestinationChange} key="destinationAirport"
                                      childrenKey="destinationAirport" header="FINAL DESTINATION"
                                      placeholder="e.g. London or LHR"
                                      required={true}/>
                    </div>
                </div>
                <div className="formDiv">
                    <p className="subSection">Did you have any connecting flights?</p>
                    <div>
                        <p>If your trip was not a direct flight, let us know.</p>
                        <ConnectedFlights
                            isValid={this.isConnectedFlightsValidityChanged}
                            handleConnectedFlightsChange={this.handleConnectedFlightsChange}/>
                    </div>
                </div>
                <div style={{display: "flex", backgroundColor: "#F4FCFF", marginTop:"20px", marginBottom:"20px",
                paddingLeft:"5px",paddingRight:"5px", height:"28px"}}>
                    <div><InfoIcon color="primary"/></div>
                    <div><p>No risk. Submitting a claim is absolutely free of charge.</p></div>
                </div>
            </form>
        );
    }
}

export default FlightSourceDestination;
