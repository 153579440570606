export class Airline {
    constructor(name, code) {
        this.code = code;
        this.name = name;
    }

    toString = () =>
    {
        return this.name ? this.name + this.code: "";
    }
}
