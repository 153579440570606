import React, {Component} from "react";
import '../../styles/radioBoxStyle.css'
import Select from "@material-ui/core/Select";

class DelayReason extends Component {

    state = {
        selection: "",
    };

    handleChange = (event) => {
        let newDelayValue = event.target.value;
        this.setState({selection: newDelayValue});
        if(event.target.value === "0"){
            this.props.handleDelayReasonChange(null);
            this.props.isValid(false);
            return;
        }
        this.props.handleDelayReasonChange(newDelayValue);
        this.props.isValid(true);
    };

    render() {
        return (
            <div><p className="subSection">What did the airline say was the reason?</p>
                <Select
                    native
                    value={this.state.selection ? this.state.selection: 0}
                    onChange={this.handleChange}
                    style={{outlineStyle: "solid", width:"100%", maxWidth:"300px", height: "40px",outlineColor: "#9bc645"}}
                >
                    <option value={0} >Select the reason</option>
                    <option value={10}>Technical Problem</option>
                    <option value={20}>Bad weather conditions</option>
                    <option value={30}>Influence by other flights</option>
                    <option value={40}>Issues with airport</option>
                    <option value={50}>Strike</option>
                    <option value={60}>No reason given</option>
                    <option value={70}>Don't remember</option>
                </Select>
            </div>);
    }
}

export default DelayReason;
