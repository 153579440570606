import React, {Component} from "react";
import '../../styles/radioBoxStyle.css'
import withStyles from "@material-ui/core/styles/withStyles";
import Radio from "@material-ui/core/Radio";

const GreenRadio = withStyles({
    root: {
        color: "#9bc645",
        '&$checked': {
            color: "#9bc645",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />);

class CancellationNotification extends Component {

    state = {
        selection: "",
    };

    handleChange = (event) => {
        this.setState({selection: event.target.value});
        this.props.handleCancelReasonChange(event.target.value);
        this.props.isValid(true);
    };

    render() {
        return (
            <div><p className="subSection">How far in advance did the airline notify you of the cancellation?</p>
                <p>Tell us how many days before the flight the airline informed you that it was canceled</p>
                <div>
                    <div className="radioLeftDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "Less than 14 days"}
                            onChange={this.handleChange}
                            value="Less than 14 days"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'Less than 14 days'}}
                        />
                        <span>Less than 14 days</span>
                    </div>
                    <div className="radioRightDivStyle">
                        <GreenRadio
                            checked={this.state.selection === "More than 14 days"}
                            onChange={this.handleChange}
                            value="More than 14 days"
                            name="radio-button-demo"
                            inputProps={{'aria-label': 'More than 14 days'}}
                        /><span>More than 14 days</span>
                    </div>
                </div>
            </div>);
    }
}

export default CancellationNotification;
